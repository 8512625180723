import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./app/App"
import firebaseConfig from "./app/firebaseConfig"
import cometChatConfig from "./app/cometChatConfig"
import { FirebaseAppProvider } from "reactfire"

import reportWebVitals from "./reportWebVitals"
import { UIKitSettingsBuilder } from "@cometchat/uikit-shared"
import { CometChatUIKit } from "@cometchat/chat-uikit-react"

// Initialize CometChat.   For some reason this next block only works if added directly in this file
import { Auth0Provider } from "@auth0/auth0-react"
;(async () => {
  const uiKitSettings = new UIKitSettingsBuilder()
    .setAppId(cometChatConfig.appId)
    .setRegion(cometChatConfig.region)
    .setAuthKey(cometChatConfig.authKey)
    .subscribePresenceForFriends()
    .build()

  try {
    await CometChatUIKit.init(uiKitSettings)
    // console.log("Initialization completed successfully")
  } catch (error) {
    console.log("Initialization failed with error:", error)
  }
})()

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <Provider store={store}>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Auth0Provider
        domain="dev-735ohfdr.us.auth0.com"
        clientId="ous6HeiMVrEsnFesK4oUDCZWz77XqWUJ"
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <App />
      </Auth0Provider>
    </FirebaseAppProvider>
  </Provider>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
