import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from "@ionic/react"
import React from "react"
import "./Header.scss"
import HeaderLogo from "../HeaderLogo/HeaderLogo"
import { useUser } from "reactfire"
import { isAdminUser } from "../../features/user/userSlice"

const Header = () => {
  const { data: user } = useUser()
  let headerText

  if (isAdminUser(user)) {
    headerText = "Doula"
  } else {
    headerText = "Mother"
  }

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <div
          slot="end"
          style={{ display: "none", fontSize: "10px", marginRight: "20px" }}
        >
          User Type: {user ? headerText : "Not logged in"}
          <br />
          {/* eslint-disable-next-line no-undef */}
          {/*Build Date: {__BUILD_DATE__}*/}
        </div>
        <div className="ion-text-center">
          <HeaderLogo />
        </div>
      </IonToolbar>
    </IonHeader>
  )
}

export default Header
