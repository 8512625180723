import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth"
import {
  setFirebaseLoggedOut,
  setFirebaseUserData,
  setFirebaseUserDocId,
  userLogin,
} from "./userSlice"
import { initializeApp } from "firebase/app"
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite"
import firebaseConfig from "../../app/firebaseConfig"
import { setDoulaLikes } from "../doulaverse/doulaverseSlice"

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export const firebaseLogout = (auth, dispatch) => {
  signOut(auth).then(
    () => {
      dispatch(setFirebaseLoggedOut())
      console.log("firebase logout completed successfully")
    },
    (error) => {
      console.log("firebase ogout failed with exception:", { error })
    },
  )
}
const firebaseGetUserData = async (user) => {
  // const app = initializeApp(firebaseConfig)
  // const db = getFirestore(app)

  const q = query(collection(db, "users"), where("uid", "==", user.uid))
  let docs = await getDocs(q)
  let docRef

  // if no userData doc found in db, then create one...
  if (docs.docs.length === 0) {
    // console.log("creating...")

    docRef = await addDoc(collection(db, "users"), {
      uid: user.uid,
      name: user.displayName,
      authProvider: "google",
      email: user.email,
    })
    console.log("creating", docRef)
  } else {
    // console.log("existing...")
    docRef = docs.docs[0]
    if (docRef.data().name !== user.displayName) {
      // console.log("editing")

      // update name in case it's changed
      setDoc(
        doc(db, "users", docRef.id),
        { name: user.displayName },
        { merge: true },
      )
    }
    // return the data object to be saved in state. if it is null, it means no data has yet been saved in firestore
  }
  return {
    userDocId: docRef.id,
    data: docRef.data().data,
    doulaLikes: docRef.data().doulaLikes,
  }
}

/*
Set listener for Firebase login updates.
 */
export const firebaseSetAuthListener = (auth, dispatch) => {
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      dispatch(userLogin(user, dispatch))
      let userData = await firebaseGetUserData(user)
      if (userData) {
        dispatch(setFirebaseUserData({ data: userData.data }))
        dispatch(setFirebaseUserDocId({ userDocId: userData.userDocId }))
        dispatch(setDoulaLikes({ data: userData.doulaLikes }))
      }
    }
  })
}

export const firebaseLoginGoogle =
  (auth, history, redirect) => async (dispatch) => {
    const provider = new GoogleAuthProvider()
    await signInWithPopup(auth, provider)
    history.push(redirect)
  }

export const firebaseSetUserData = (fieldName, fieldData, userDocId) => {
  let data = {}
  data[fieldName] = fieldData
  setDoc(doc(db, "users", userDocId), data, { merge: true })
}
