import { IonButton, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react"
import { useAuth0 } from "@auth0/auth0-react"

import React from "react"
import { Link, useHistory } from "react-router-dom"
import { useAppDispatch } from "../../hooks/hooks"
import { isAdminUser } from "../../features/user/userSlice"
import { useAuth, useUser } from "reactfire"
import { userLogout } from "../../features/user/userSlice"

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const auth = useAuth()
  const { logout: logoutAuth0 } = useAuth0()
  const handleLogout = async () => {
    dispatch(userLogout(auth, logoutAuth0))
  }

  const { data: user } = useUser()

  return (
    <IonContent className="ion-padding dashboard" fullscreen>
      <IonGrid>
        <IonRow className="ion-text-center">
          <IonCol>
            <h1>
              {isAdminUser(user) ? "Doula" : "Mother"} Dashboard for
              <br />
              {user ? user.displayName : ""}!
            </h1>
            <h2>{user ? user.email : ""}</h2>
          </IonCol>
        </IonRow>
        {isAdminUser(user) ? (
          <IonRow className="ion-text-center">
            <IonCol>
              <Link to="/chat">
                <IonButton>Chat</IonButton>
              </Link>
            </IonCol>
          </IonRow>
        ) : (
          <React.Fragment>
            {/*<IonRow className="ion-text-center">*/}
            {/*  <IonCol>*/}
            {/*    <Link to="/mothers/profile?step=1">*/}
            {/*      <IonButton>Mama Profile</IonButton>*/}
            {/*    </Link>*/}
            {/*  </IonCol>*/}
            {/*</IonRow>*/}
            <IonRow className="ion-text-center">
              <IonCol>
                <Link to="/doulaverse/search">
                  <IonButton>Doulaverse</IonButton>
                </Link>
              </IonCol>
            </IonRow>
          </React.Fragment>
        )}
        <IonRow className="ion-text-center">
          <IonCol>
            <IonButton onClick={handleLogout}>Logout</IonButton>
          </IonCol>
        </IonRow>

        {/*<IonRow className="ion-text-center">*/}
        {/*  <IonCol>*/}
        {/*    <IonModal isOpen={showAlert}>*/}
        {/*      <AlertBox />*/}
        {/*    </IonModal>*/}
        {/*    <IonButton*/}
        {/*      onClick={() =>*/}
        {/*        presentAlert({*/}
        {/*          header: "Alert",*/}
        {/*          subHeader: "Important message",*/}
        {/*          message: "This is an alert!",*/}
        {/*          buttons: [*/}
        {/*            {*/}
        {/*              text: "Cancel",*/}
        {/*              role: "cancel",*/}
        {/*              handler: () => {*/}
        {/*                console.log("Alert canceled");*/}
        {/*              },*/}
        {/*            },*/}
        {/*            {*/}
        {/*              text: "OK",*/}
        {/*              role: "confirm",*/}
        {/*              handler: () => {*/}
        {/*                console.log("Alert confirmed");*/}
        {/*              },*/}
        {/*            },*/}
        {/*          ],*/}
        {/*        })*/}
        {/*      }*/}
        {/*    >*/}
        {/*      Show Alert*/}
        {/*    </IonButton>*/}
        {/*  </IonCol>*/}
        {/*</IonRow>*/}
      </IonGrid>
    </IonContent>
  )
}

export default Dashboard
