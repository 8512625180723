import { IonContent } from "@ionic/react"

import React, { useEffect, useState } from "react"
import { find, map } from "lodash"

import {
  CometChatMessages,
  CometChatUIKit,
  // MessageComposerConfiguration,
  // MessageHeaderConfiguration,
} from "@cometchat/chat-uikit-react"

import { useLocation, useParams } from "react-router-dom"
// import { MessageComposerStyle, MessagesStyle } from "@cometchat/uikit-shared"
import { selectAllDoulas } from "../../features/doulaverse/doulaverseSlice"
import { useSelector } from "react-redux"
import { cometChatGetUserObjects } from "../../features/user/cometChat"
import { adminUids, selectCCLoggedIn } from "../../features/user/userSlice"
import { User } from "@cometchat/chat-sdk-javascript"

const DoulaChat = () => {
  const location = useLocation()

  // // note, removing these styles, even if they're empty, will change the look and feel
  // const mcStyle = new MessageComposerStyle({})
  // const mStyle = new MessagesStyle({
  //   // border: "1px solid rgb(201, 255, 191)",
  // })
  //
  // const mhConfig = new MessageHeaderConfiguration({
  //   hideBackButton: false,
  //   onBack: () => {
  //     history.goBack()
  //   },
  // })
  //
  // const mcConfig = new MessageComposerConfiguration({
  //   messageComposerStyle: mcStyle,
  // })

  const params = useParams()
  // @ts-ignore
  let row = find(useSelector(selectAllDoulas), { id: parseInt(params.id) })

  // see comments in DoulaHeader
  const [currentUser, setCurrentUser] = useState<any | User>()
  useEffect(() => {
    // @ts-ignore
    if (location.state?.user) {
      // @ts-ignore
      console.log(location.state?.user)
      // @ts-ignore
      setCurrentUser(location.state?.user)
    } else if (!currentUser) {
      console.log("looking up")
      cometChatGetUserObjects([adminUids[row.alias.toLowerCase()]]).then(
        (result) => {
          setCurrentUser(result.length && result[0])
        },
      )
    }
  }, [currentUser])

  //todo: page reload, then this doesn't work usually
  console.log("current user: ", currentUser)
  const [isCCLoggedIn, setIsCCLoggedIn] = useState<boolean>()
  useEffect(() => {
    CometChatUIKit.getLoggedinUser().then((user) => {
      if (user) {
        setIsCCLoggedIn(true)
        console.log("*** logged in", user)
      } else {
        console.log("*** not logged in")
      }
    })
  }, [isCCLoggedIn])

  return (
    <IonContent className="ion-padding">
      {isCCLoggedIn && currentUser && (
        <CometChatMessages
          user={currentUser}
          // messagesStyle={mStyle}
          // messageHeaderConfiguration={mhConfig}
          // messageComposerConfiguration={mcConfig}
        />
      )}
    </IonContent>
  )
}

export default DoulaChat
