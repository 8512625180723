import {
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonRow,
} from "@ionic/react"
import React from "react"
import data from "../../../dummyData/doulas.json"
import fields from "../../../dummyData/doulaFields.json"
import { find } from "lodash"
import "../../app/Doulaverse.scss"

import { useParams } from "react-router-dom"
import DoulaHeader from "../../components/DoulaHeader/DoulaHeader"
// import DoulaHeader from "../DoulaHeader/DoulaHeader.tsx";
// import DoulaverseFooter from "../DoulaverseFooter/DoulaverseFooter.tsx";

const Doula = () => {
  const routeParams = useParams()
  // @ts-ignore
  const row = find(data, (x) => x.id.toString() === routeParams.id)

  return (
    <>
      <IonContent className="ion-padding" fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1>Doula</h1>
            </IonCol>
          </IonRow>
          {row && (
            <>
              <DoulaHeader row={row} userObject={null} />
              {Object.keys(fields).map((field, index) => {
                return (
                  <IonRow key={index}>
                    <IonCol>
                      <IonCheckbox disabled checked={row[field]} />
                      &nbsp;&nbsp;&nbsp;{field}
                    </IonCol>
                  </IonRow>
                )
              })}
            </>
          )}
        </IonGrid>
      </IonContent>
      {/*<DoulaverseFooter />*/}
    </>
  )
}

export default Doula
