import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react"
import React from "react"
import { useAuth0 } from "@auth0/auth0-react"

const LoginAuth0 = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <IonPage id="main-content">
      <IonContent className="login ion-padding" fullscreen>
        <IonGrid>
          <IonRow className="ion-text-center">
            <IonCol offset="3" size="6">
              <br />
              <br />
              <br />
              <br />
              <h1>Welcome to the closed preview of Motherline</h1>
              <br />
              <br />
              If you have been given access, you can login with your Google
              credentials below.
              <br />
              <br />
              <br />
              If you'd like more info, please{" "}
              <a href="mailto:info@motherline.app">contact us</a>!
              <br />
              <br />
              <br />
              <IonButton
                className="btn-margin"
                onClick={() => loginWithRedirect()}
              >
                Log in
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default LoginAuth0
