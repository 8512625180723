import { concat, uniq } from "lodash"
import { chatbubbles, heart } from "ionicons/icons"
import { IonCol, IonIcon, IonImg, IonRow } from "@ionic/react"
import { Link } from "react-router-dom"
import { remove } from "lodash"
import { useUser } from "reactfire"
import { useSelector } from "react-redux"
import {
  setDoulaLikes,
  selectAllDoulaFavorites,
} from "../../features/doulaverse/doulaverseSlice"
import { useAppDispatch } from "../../hooks/hooks"
import { useLocation } from "react-router-dom"
import { selectUserDocId } from "../../features/user/userSlice"
import { firebaseSetUserData } from "../../features/user/firebase"
const DoulaHeader = ({ row, userObject }) => {
  let doulaLikes = useSelector(selectAllDoulaFavorites)
  const dispatch = useAppDispatch()
  const { data: user } = useUser()
  const location = useLocation()
  const userDocId = useSelector(selectUserDocId)
  let doulaLikesNew
  const setFavorite = (e) => {
    let friendId = e.target.getAttribute("data-id")
    let tmp = [...doulaLikes]
    if (tmp.includes(friendId)) {
      // console.log("remove")
      remove(tmp, (n) => n === friendId)
      doulaLikesNew = { data: tmp }
    } else {
      // console.log("add")
      doulaLikesNew = { data: uniq(concat(tmp, friendId)) }
    }
    dispatch(setDoulaLikes(doulaLikesNew))
    firebaseSetUserData("doulaLikes", doulaLikesNew.data, userDocId)
  }

  /*
   * This component can be loaded in 3 ways:
   * 1. As part of the DoulaResults list, in which case userObject is set for each row
   * 2. From the Doula page after being clicked from the DoulaResults page.  UserObject is passed through location state.
   * 3. From the Doula page when loaded directly via URL (or if you're on #2 above, then do a page reload).
   *    There is no Link state, so UserObject needs to be looked up.
   *    In this event, don't need to look it up here, we can do it in the DoulaChat component
   * */
  // see if we're on a doula page (either #2 or #3 above):
  if (location.pathname.indexOf("/doulaverse/doula/") >= 0) {
    // #2 above:
    // @ts-ignore
    if (!userObject && location.state?.user) userObject = location.state?.user

    // For #3 above, we can handle that in the Doula Chat component
  }

  return (
    <IonRow className="ion-align-items-center">
      <IonCol size="4">
        {user ? (
          <Link
            to={{
              pathname: "/doulaverse/doula/" + row.id,
              state: {
                user: userObject,
              },
            }}
          >
            <IonImg
              className="headshot"
              src={"/assets/headshots/" + row.image + ".jpg"}
              alt={row.firstName + " " + row.alias + ". " + row.lastName}
            />
          </Link>
        ) : (
          <IonImg
            className="headshot"
            src={"/assets/headshots/" + row.image + ".jpg"}
            alt={row.firstName + " " + row.alias + ". " + row.lastName}
          />
        )}
      </IonCol>

      <IonCol size="5">
        {/* When logged in and not on doula page, display doula name as a link */}
        {user && location.pathname.indexOf("/doulaverse/doula/") === -1 ? (
          <Link
            to={{
              pathname: "/doulaverse/doula/" + row.id,
              state: {
                user: userObject,
              },
            }}
          >
            {row.firstName + " " + row.alias + ". "}
            <br />
            {row.lastName}
          </Link>
        ) : !user ? (
          row.firstName + " " + row.lastName.substr(0, 1) + "."
        ) : (
          row.firstName + " " + row.lastName
        )}
      </IonCol>

      {user && (
        <IonCol size="3">
          <Link
            to={{
              pathname: "/doulaverse/doula/" + row.id + "/chat",
              state: {
                user: userObject,
              },
            }}
          >
            <IonIcon
              className={"favorite " + userObject?.status}
              icon={chatbubbles}
              data-alias={row.alias}
              data-name={row.firstName + " " + row.alias + ". " + row.lastName}
            />
          </Link>
          {/*{doulaUsers.length > 0 && (*/}
          {/*  <CometChatCallButtons user={doulaUsers[0]} />*/}
          {/*)}*/}
          <IonIcon
            onClick={setFavorite}
            className={
              "favorite " +
              (doulaLikes.includes(row.id.toString()) ? "checked" : "")
            }
            icon={heart}
            data-id={row.id}
          />
        </IonCol>
      )}
    </IonRow>
  )
}

export default DoulaHeader
