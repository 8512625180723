const firebaseConfig = {
  apiKey: "AIzaSyCp0ElYMkKnxy-nURnC2gTa3810WdWUIbk",
  authDomain: "motherline-c6c92.firebaseapp.com",
  projectId: "motherline-c6c92",
  storageBucket: "motherline-c6c92.appspot.com",
  messagingSenderId: "398697975040",
  appId: "1:398697975040:web:bd95ee1c1b4824a6a677e5",
  measurementId: "G-60JB14SJVX",
}
export default firebaseConfig
