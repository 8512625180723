import { map, find } from "lodash"
import {
  adminUids,
  setCCAuthToken,
  setCCLoggedIn,
  setCCLoggedOut,
} from "./userSlice"
import { getApiHost } from "../../utils/utils"
import { CometChatUIKit } from "@cometchat/chat-uikit-react"
import { CometChat, User } from "@cometchat/chat-sdk-javascript"

export const cometChatGetAuthToken = async (firebaseToken, dispatch) => {
  // console.log("getting cc auth token")
  const headers = {
    Authorization: "Bearer " + firebaseToken,
  }

  const response = await fetch(getApiHost() + "/cometChatGetAuthToken", {
    headers: headers,
  })
  let json = await response.json()
  if (json) {
    let cometChatAuthToken = json.authToken
    dispatch(setCCAuthToken({ cometChatAuthToken: cometChatAuthToken }))
    cometChatLogin(cometChatAuthToken, dispatch)
  }
}
export const cometChatLogout = (dispatch) => {
  CometChatUIKit.logout().then(
    () => {
      dispatch(setCCLoggedOut())
      console.log("UIKit Logout completed successfully")
    },
    (error) => {
      console.log("UIKit Logout failed with exception:", { error })
    },
  )
}
// export const cometChatInit = async () => {
//   const uiKitSettings = new UIKitSettingsBuilder()
//     .setAppId(cometChatConfig.appId)
//     .setRegion(cometChatConfig.region)
//     .setAuthKey(cometChatConfig.authKey)
//     .subscribePresenceForFriends()
//     .build()
//
//   try {
//     await CometChatUIKit.init(uiKitSettings)
//     // console.log("Initialization completed successfully")
//   } catch (error) {
//     console.log("Initialization failed with error:", error)
//   }
// }

/*
TMP: For now, download user objects for 3 admin users
 */
export const cometChatGetUserObjects = async (
  userList,
): Promise<any[] | [] | User[]> => {
  let usersRequest = new CometChat.UsersRequestBuilder()
    .setLimit(10)
    .setUIDs(userList)
    .build()

  let result = await usersRequest.fetchNext().then(
    (userList) => {
      console.log("User list received:", userList)
      return userList
    },
    (error) => {
      console.log("User list fetching failed with error:", error)
      return []
    },
  )
  return result
}
const cometChatLogin = (cometChatAuthToken, dispatch) => {
  CometChatUIKit.getLoggedinUser()
    .then((user) => {
      if (!user) {
        //Login user
        // console.log("not logged in")
        CometChatUIKit.loginWithAuthToken(cometChatAuthToken)
          .then((user) => {
            // console.log("Login Successful:", { user })
          })
          .catch(console.log)
      } else {
        // console.log("Already logged in:", { user })
      }
      dispatch(setCCLoggedIn())
    })
    .catch(console.log)
}

export const cometChatCurrentDoula = (userList, row) =>
  find(userList, { uid: adminUids[row.alias.toLowerCase()] })
