import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import fields from "../../../dummyData/doulaFields.json"
import doulas from "../../../dummyData/doulas.json"
import { shuffle } from "lodash"
import { firebaseSetUserData } from "../user/firebase"
interface DoulaverseState {
  searchFields: object
  searchFieldSelections: object
  doulas: object
  doulaLikes: object
  doulaMatches: object
}

let searchFieldSelections = Object.assign({}, fields)
Object.keys(searchFieldSelections).forEach((item) => {
  searchFieldSelections[item] = false
})

const initialState: DoulaverseState = {
  searchFields: fields,
  searchFieldSelections: searchFieldSelections,
  doulas: doulas,
  // doulas: shuffle(doulas),
  doulaLikes: [],
  doulaMatches: [],
}

export const doulaverseSlice = createSlice({
  name: "doula",
  initialState: initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<object>) => {
      state.searchFieldSelections = action.payload
    },
    updateForm: (state, action: PayloadAction<object>) => {
      return { ...state, ...action.payload }
    },
    setDoulaLikes: (state, { payload }) => {
      // @ts-ignore
      state.doulaLikes = payload.data
    },
    setDoulaSearchFields: (state, { payload }) => {
      // @ts-ignore
      state.doulaSearchFields = payload.data
    },
  },
})

export const selectFieldValue = (state, field) =>
  state.doulaverse.searchFieldSelections[field]
export const selectAllDoulas = (state) => state.doulaverse.doulas

const selectAllDoulaFavoritesInner = (state) =>
  state.doulaverse.doulaLikes || []
export const selectAllDoulaFavorites = createSelector(
  [selectAllDoulaFavoritesInner],
  (items) => items,
)
export const selectFieldSelections = (state) =>
  state.doulaverse.searchFieldSelections

/*
Return a new array with only whichever fields user had selected as true
 */
export const selectFieldTrueSelections = createSelector(
  [selectFieldSelections],
  (a) => {
    let newObject = {}
    for (const property in a) {
      if (a[property]) newObject[property] = true
    }
    return newObject
  },
)
export const {
  updateFilters,
  updateForm,
  setDoulaLikes,
  setDoulaSearchFields,
} = doulaverseSlice.actions

export default doulaverseSlice.reducer
