import React, { Suspense, useState } from "react"
import { Redirect } from "react-router"
import { Route, BrowserRouter } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  IonApp,
  IonGrid,
  IonLoading,
  IonPage,
  IonRouterOutlet,
  IonRow,
  setupIonicReact,
} from "@ionic/react"

/* Redux */
import { selectCCLoggedIn } from "../features/user/userSlice"

/* Hooks */
import { useAppDispatch } from "../hooks/hooks"

/* Firebase/ReactFire */
import {
  AuthProvider,
  useFirebaseApp,
  FirestoreProvider,
  useSigninCheck,
} from "reactfire"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { firebaseSetAuthListener } from "../features/user/firebase"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./variables.scss"
import "./App.scss"

/* Pages */
import Home from "../pages/Home/Home"
import Login from "../pages/Login/Login"
import Dashboard from "../pages/Dashboard/Dashboard"
import MothersFindOut from "../pages/MothersFindOut/MothersFindOut"
import DoulasFindOut from "../pages/DoulasFindOut/DoulasFindOut"
import DoulaverseSearch from "../pages/DoulaverseSearch/DoulaverseSearch"
import DoulaverseResults from "../pages/DoulaverseResults/DoulaverseResults"
import Doula from "../pages/Doula/Doula"
import DoulaChat from "../pages/DoulaChat/DoulaChat"
import AdminChat from "../pages/AdminChat/AdminChat"

/* Components */
import Header from "../components/Header/Header"
import Menu from "../components/Menu/Menu"
import { CometChatIncomingCall } from "@cometchat/chat-uikit-react"
import { useAuth0 } from "@auth0/auth0-react"
import LoginAuth0 from "../components/LoginAuth0/LoginAuth0"
import { isLocalhost } from "../utils/utils"

setupIonicReact()

function App() {
  //todo:debug token:
  // const APP_CHECK_TOKEN = "70724B8C-D747-4815-9F46-83D4A7992923"
  // // const APP_CHECK_TOKEN = "6LfzlRYpAAAAAPySLCftsfr73vIlZM88kuhlNlTI"
  const app = useFirebaseApp()
  const { isAuthenticated: isLoggedInAuth0 } = useAuth0()
  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
  //   isTokenAutoRefreshEnabled: true,
  // })

  const [firebaseListener, setFirebaseListener] = useState<boolean>(false)
  const firestoreDatabase = getFirestore(app)
  const auth = getAuth(app)
  const dispatch = useAppDispatch()
  /* track state otherwise listener gets set twice */
  if (!firebaseListener) {
    firebaseSetAuthListener(auth, dispatch)
    setFirebaseListener(true)
  }
  const isCCLoggedIn = useSelector(selectCCLoggedIn)

  // Check for dev/test mode however your app tracks that.
  // `process.env.NODE_ENV` is a common React pattern
  // if (process.env.NODE_ENV !== "production") {
  //   // Set up emulators
  //   connectFirestoreEmulator(firestoreDatabase, "localhost", 9000);
  //   connectAuthEmulator(auth, "http://localhost:9099");
  // }
  //

  const ProtectedRoute = ({
    children,
    location,
    ...rest
  }: React.PropsWithChildren<any>) => {
    const { status, data: signInCheckResult } = useSigninCheck()
    if (status === "loading") {
      return <IonLoading />
    }
    return (
      <Route
        {...rest}
        render={({ location }) =>
          signInCheckResult.signedIn === true ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )
        }
      />
    )
  }

  const PublicRoute = ({
    children,
    location,
    ...rest
  }: React.PropsWithChildren<any>) => {
    const { status, data: signInCheckResult } = useSigninCheck()
    if (status === "loading") {
      return <IonLoading />
    }
    return (
      <Route
        {...rest}
        render={({ location }) =>
          signInCheckResult.signedIn === false ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/dashboard",
              }}
            />
          )
        }
      />
    )
  }

  return (
    <IonApp>
      {/*<AppCheckProvider sdk={appCheck}>*/}
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestoreDatabase}>
          <IonGrid className="vertically-center">
            <IonRow className="ion-text-center">
              <CometChatIncomingCall />
              {/*<CometChatOutgoingCall  />*/}
              {/*<CometChatOngoingCall />*/}
            </IonRow>
          </IonGrid>
          <BrowserRouter>
            {isLoggedInAuth0 || isLocalhost() ? (
              <IonRouterOutlet>
                <Menu />
                <IonPage id="main-content">
                  <Header />
                  <Suspense fallback={<div>Loading...</div>}>
                    <PublicRoute exact path="/">
                      <Home />
                    </PublicRoute>
                    <PublicRoute exact path="/login">
                      <Login loginType="login" />
                    </PublicRoute>
                    <PublicRoute exact path="/mothers/signup">
                      <Login loginType="sign up" />
                    </PublicRoute>
                    <PublicRoute exact path="/doulas/signup">
                      <Login loginType="sign up" />
                    </PublicRoute>
                    <PublicRoute exact path="/mothers/findout">
                      <MothersFindOut />
                    </PublicRoute>
                    <PublicRoute exact path="/doulas/findout">
                      <DoulasFindOut />
                    </PublicRoute>
                    {/* Search and matches pages are both public and protected routes */}
                    <Route exact path="/doulaverse/search">
                      <DoulaverseSearch />
                    </Route>
                    <Route exact path="/doulaverse/matches">
                      <DoulaverseResults type="matches" />
                    </Route>
                    <ProtectedRoute exact path="/doulaverse/favorites">
                      <DoulaverseResults type="favorites" />
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/doulaverse/doula/:id">
                      <Doula />
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/doulaverse/doula/:id/chat">
                      {isCCLoggedIn ? <DoulaChat /> : <IonLoading />}
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/chat">
                      {isCCLoggedIn ? <AdminChat /> : <IonLoading />}
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/dashboard">
                      <Dashboard />
                    </ProtectedRoute>
                  </Suspense>
                </IonPage>
              </IonRouterOutlet>
            ) : (
              <LoginAuth0 />
            )}
          </BrowserRouter>
        </FirestoreProvider>
      </AuthProvider>
      {/*</AppCheckProvider>*/}
    </IonApp>
  )
}

export default App
