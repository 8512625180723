import { configureStore } from "@reduxjs/toolkit"
import userReducer from "../features/user/userSlice"
import doulaverseReducer from "../features/doulaverse/doulaverseSlice"

export const store = configureStore({
  reducer: {
    user: userReducer,
    doulaverse: doulaverseReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >
