import { IonContent } from "@ionic/react"

import React from "react"

import {
  CometChatConversations,
  CometChatConversationsWithMessages,
  CometChatUsersWithMessages,
} from "@cometchat/chat-uikit-react"
import {
  // WithMessagesStyle,
  ConversationsConfiguration,
  // SelectionMode,
  ConversationsStyle,
} from "@cometchat/uikit-shared"

const AdminChat = () => {
  // const { cometChatUserLoggedIn } = useAppContext();
  // const cwmStyle = new WithMessagesStyle({
  //   width: "600px",
  //   height: "600px",
  // });
  const cwfConfig = new ConversationsConfiguration({
    // titleAlignment: "center",
    // selectionMode: SelectionMode.multiple,
    // conversationsStyle: new ConversationsStyle({
    //   onlineStatusColor: "#BFFF00",
    // }),
  })

  return (
    <IonContent className="ion-padding" fullscreen>
      {/*<CometChatConversations*/}
      {/*  title="Recent Chats"*/}
      {/*  // titleAlignment="center"*/}
      {/*  // conversationsStyle={conversationsStyle}*/}
      {/*/>*/}
      <CometChatConversationsWithMessages
        conversationsConfiguration={cwfConfig}
        isMobileView={true}
        // conversationsWithMessagesStyle={cwmStyle}
      />
      {/*<CometChatUsersWithMessages />*/}
    </IonContent>
  )
}

export default AdminChat
