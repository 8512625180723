import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react"
import React, { Fragment, useEffect, useState } from "react"
import "../../app/Doulaverse.scss"
import DoulaverseFooter from "../../components/DoulaverseFooter/DoulaverseFooter"
import DoulaHeader from "../../components/DoulaHeader/DoulaHeader"
import {
  selectAllDoulas,
  selectAllDoulaFavorites,
  selectFieldTrueSelections,
} from "../../features/doulaverse/doulaverseSlice"
import { useSelector } from "react-redux"
import { filter, matches, map } from "lodash"
import {
  cometChatCurrentDoula,
  cometChatGetUserObjects,
} from "../../features/user/cometChat"
import { User } from "@cometchat/chat-sdk-javascript"
import { adminUids, selectCCLoggedIn } from "../../features/user/userSlice"

const DoulaverseResults = ({ type }) => {
  const isCCLoggedIn = useSelector(selectCCLoggedIn)
  const [currentPageUserList, setCurrentPageUserList] = useState<
    any[] | [] | User[]
  >([])

  let page = 0
  let pageCount = 10
  let allDoulas = useSelector(selectAllDoulas)
  let doulaFavorites = useSelector(selectAllDoulaFavorites)
  let doulaTrueSelections = useSelector(selectFieldTrueSelections)
  const getMatches = () => filter(allDoulas, matches(doulaTrueSelections))
  //
  const getFavorites = () =>
    filter(allDoulas, (doula) => doulaFavorites.includes(doula.id.toString()))

  // load admin user online status
  useEffect(() => {
    //can't do async in useeffect
    ;(async function () {
      if (isCCLoggedIn) {
        setCurrentPageUserList(
          // for now just sending in the fake list of adminUids, but eventually will send actual list of all doulas
          // on this page
          await cometChatGetUserObjects(map(adminUids, (uid) => uid)),
          // await cometChatGetUserObjects(map([doulas on this page], (uid) => uid)),
        )
      }
    })()
  }, [])

  return (
    <>
      <IonContent className="ion-padding" fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1>Doulaverse {type === "matches" ? "Matches" : "Favorites"}</h1>
            </IonCol>
          </IonRow>

          {(type === "matches" ? getMatches() : getFavorites())
            .slice(page, pageCount)
            .map((row) => (
              <DoulaHeader
                key={row.email}
                row={row}
                userObject={cometChatCurrentDoula(currentPageUserList, row)}
              />
            ))}
        </IonGrid>
      </IonContent>
      <DoulaverseFooter />
    </>
  )
}

export default DoulaverseResults
