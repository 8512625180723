import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonMenuButton,
  IonToolbar,
} from "@ionic/react"
import { menuController } from "@ionic/core/components"
import { Link } from "react-router-dom"
import React from "react"
import HeaderLogo from "../HeaderLogo/HeaderLogo"
import "./Menu.scss"
import { useUser } from "reactfire"
const MenuProtected = () => {
  return (
    <IonList>
      <Link to="/dashboard">
        <IonItem>Dashboard</IonItem>
      </Link>
      <Link to="/mothers/profile?step=1">
        <IonItem>Profile</IonItem>
      </Link>
      <Link to="/doulaverse/filters">
        <IonItem>Doulaverse</IonItem>
      </Link>
      {/*<IonItem onClick={handleLogout}>Logout</IonItem>*/}
    </IonList>
  )
}

const MenuPublic = () => (
  <IonList>
    <Link to="/login">
      <IonItem>Login</IonItem>
    </Link>
    <Link to="/doulas/findout">
      <IonItem>I want to give support</IonItem>
    </Link>
    <Link to="/mothers/findout">
      <IonItem>I want to get support</IonItem>
    </Link>
  </IonList>
)

const Menu = () => {
  const { data: user } = useUser()
  return (
    <IonMenu
      onClick={() => {
        menuController.close()
      }}
      contentId="main-content"
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <HeaderLogo />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {user ? <MenuProtected /> : <MenuPublic />}
      </IonContent>
    </IonMenu>
  )
}

export default Menu
