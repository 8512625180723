import {
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonItemGroup,
  IonRow,
} from "@ionic/react"
import React from "react"
import fields from "../../../dummyData/doulaFields.json"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import DoulaverseFooter from "../../components/DoulaverseFooter/DoulaverseFooter"
import { store } from "../../app/store"
import { useAppDispatch } from "../../hooks/hooks"
import {
  selectFieldSelections,
  selectFieldValue,
  updateFilters,
} from "../../features/doulaverse/doulaverseSlice"
import { useSelector } from "react-redux"
import { firebaseSetUserData } from "../../features/user/firebase"
import { selectUserDocId } from "../../features/user/userSlice"

const onSubmit = (data, history, dispatch) => {
  dispatch(updateFilters(data))
  console.log(data)

  history.push("/doulaverse/matches")
}

const DoulaverseSearch = () => {
  const userDocId = useSelector(selectUserDocId)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const defaultValues = useSelector(selectFieldSelections)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  })

  return (
    <>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1>Doulaverse Search</h1>
            </IonCol>
          </IonRow>
          <IonItemGroup>
            <form
              id="DoulaverseSearchForm"
              onSubmit={handleSubmit((data) => {
                onSubmit(data, history, dispatch)
              })}
            >
              {Object.keys(fields).map((field, index) => (
                <IonItem key={index}>
                  <IonCheckbox
                    name={field}
                    checked={selectFieldValue(store.getState(), field)}
                    {...register(field)}
                    onIonChange={(e) => {
                      setValue(field, e.target.checked)
                    }}
                  >
                    {fields[field]}
                  </IonCheckbox>
                </IonItem>
              ))}
            </form>
          </IonItemGroup>
        </IonGrid>
      </IonContent>
      <DoulaverseFooter />
    </>
  )
}

export default DoulaverseSearch
