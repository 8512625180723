import {
  IonButton,
  IonButtons,
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonRow,
  IonToolbar,
} from "@ionic/react"
import { flame, heart, person, star, newspaper, help } from "ionicons/icons"
import { Link, useLocation } from "react-router-dom"
import React from "react"
import { useUser } from "reactfire"
const DoulaverseFooter = () => {
  const { data: user } = useUser()

  let buttons = user
    ? [
        { path: "/doulaverse/favorites", icon: heart, text: "" },
        { path: "/doulaverse/matches", icon: flame, text: "" },
        { path: "/doulaverse/search", icon: star, text: "" },
        { path: "#", icon: person, text: "" },
      ]
    : [
        { path: "/mothers/findout", icon: help, text: "Learn more" },
        {
          path: "/mothers/signup",
          icon: newspaper,
          text: "Signup",
        },
      ]
  const location = useLocation()

  return (
    <IonFooter>
      {location.pathname === "/doulaverse/search" && (
        <IonToolbar>
          <IonButtons>
            <IonGrid>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonButton
                    fill="solid"
                    type="submit"
                    form="DoulaverseSearchForm"
                  >
                    <IonIcon icon={flame} />
                    Match Me
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonButtons>
        </IonToolbar>
      )}
      <IonToolbar>
        <IonButtons>
          <IonGrid>
            <IonRow
              className={buttons.length === 2 ? "two-across" : "four-across"}
            >
              {buttons.map((button, x) => (
                <IonCol key={x} className="ion-text-center">
                  <Link to={button.path}>
                    <IonButton
                      fill={user ? "default" : "solid"}
                      className="tab"
                    >
                      {button.icon && (
                        <IonIcon
                          className={
                            "tab " +
                            (location.pathname === button.path ? "current" : "")
                          }
                          icon={button.icon}
                        />
                      )}
                      {button.text && button.text}
                    </IonButton>
                  </Link>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  )
}

export default DoulaverseFooter
