import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonRow,
} from "@ionic/react"

import "./Home.scss"
import React from "react"
import { Link } from "react-router-dom"

const Home = () => {
  return (
    <IonContent className="ion-padding">
      <IonGrid className="vertically-center">
        <IonRow>
          <IonCol className="intro">
            <h4>doula</h4>
            <h4>support</h4>
            <h4>for everyone</h4>
          </IonCol>
          <IonCol>
            <IonImg src="/assets/doula.jpeg" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonRow className="ion-text-center">
              <IonCol>
                <Link to="/login">
                  <IonButton shape="round" className="round first">
                    Login
                  </IonButton>
                </Link>
              </IonCol>
              <IonCol>
                <h2 className="first ion-text-left">I have an account</h2>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonRow className="ion-text-left">
              <IonCol>
                <h2>
                  <br />
                  I'm new!
                  <br />I want to...
                </h2>
              </IonCol>
              <IonCol>
                <Link to="/mothers/findout">
                  <IonButton shape="round" className="round second">
                    get
                    <br />
                    support
                  </IonButton>
                </Link>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol>
                <Link to="/doulas/findout">
                  <IonButton shape="round" className="round third">
                    give
                    <br />
                    support
                  </IonButton>
                </Link>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default Home
