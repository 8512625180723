import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonRow,
} from "@ionic/react"
import "./Login.scss"
import React from "react"
import { useHistory } from "react-router"
import { logoGoogle, logoFacebook } from "ionicons/icons"
import { capitalize } from "lodash"
import { Link } from "react-router-dom"
import { useAppDispatch } from "../../hooks/hooks"
import { firebaseLoginGoogle } from "../../features/user/firebase"
import { useAuth } from "reactfire"

const Login = ({ loginType }: { loginType: string }) => {
  const auth = useAuth()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const handleLoginGoogle = async () => {
    let redirect =
      loginType === "login" ? "/dashboard" : "/mothers/profile?step=1"

    dispatch(firebaseLoginGoogle(auth, history, redirect))
  }
  return (
    <IonContent className="login ion-padding" fullscreen>
      <IonGrid>
        <IonRow className="ion-text-center ion-margin-top">
          <IonCol>
            <IonButton onClick={() => handleLoginGoogle()}>
              {capitalize(loginType)} with &nbsp;
              <IonIcon icon={logoGoogle} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <IonButton onClick={() => alert("Not yet implemented...")}>
              {capitalize(loginType)} with &nbsp;
              <IonIcon icon={logoFacebook} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <hr />
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <IonInput
              name="userName"
              type="text"
              label="Username"
              labelPlacement="fixed"
              className="ion-margin-bottom"
            />
            <IonInput
              name="password"
              type="password"
              label="Password"
              labelPlacement="fixed"
              className="ion-margin-bottom"
            />

            <IonButton onClick={() => alert("Not yet implemented...")}>
              {capitalize(loginType)}
            </IonButton>
            <br />
            <Link
              onClick={() => {
                alert("Not yet implemented...")
              }}
              to="#"
            >
              Reset Password
            </Link>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default Login
