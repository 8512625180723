import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react"

import React from "react"

const DoulasFindOut = () => {
  return (
    <IonContent className="ion-padding" fullscreen>
      <IonGrid>
        <IonRow>
          <IonCol>
            <h1>Motherline</h1>
            <h2>
              is an app that makes doula support accessible to everyone - so all
              mothers can make informed choices, get respectful care, & love the
              process
            </h2>

            <ul>
              <li>get paid for remote doula work from home and on your time</li>
              <li>
                keep those dinner plans with friends, make the school play, make
                it to the holiday gatherings, and stay for the whole birthday
                party
              </li>
              <li>
                prevent burn-out. give yourself more flexibility & freedom
              </li>
              <li>
                support people who would not otherwise have access to a doula
              </li>
              <li>bring visibility to the importance of doula support</li>
            </ul>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default DoulasFindOut
