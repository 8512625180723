import { IonButton, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react"

import React from "react"
import { Link } from "react-router-dom"

const MothersFindOut = () => {
  return (
    <IonContent className="ion-padding" fullscreen>
      <IonGrid>
        <IonRow>
          <IonCol>
            <h1>Motherline</h1>
            <h2>
              is an app that makes doula support accessible so you can make
              informed choices, get respectful care, love your birth experience
            </h2>

            <ul>
              <li>no contract, no down payments, no commitments</li>
              <li>pay as you go only for as much support as you need</li>
              <li>pick a doula’s brain</li>
              <li>get your questions answered ASAP</li>
              <li>doulas on call and on demand 24/7</li>
              <li>pay-by-the-minute at $1/minute</li>
              <li>access by chat, phone, or video.</li>
              <li>
                choose your doula or let the first available doula respond
              </li>
            </ul>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <Link to="/doulaverse/search">
              <IonButton>Explore Doulaverse</IonButton>
            </Link>
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <Link to="/mothers/signup">
              <IonButton>Sign Up Now</IonButton>
            </Link>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default MothersFindOut
