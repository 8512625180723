// based on: https://github.com/aaronksaunders/quick-intro-reactfire1/blob/redux-toolkit/src/index.js
// redux-toolkit branch
// note that reactfire is used for auth, but i couldn't get it working for firestore db access if I tried
// to put that code in here (passing the useFirestore hooks as function parameters to this file, etc.).
// so i'm using regular firebase commands to access firestore

import { createSlice } from "@reduxjs/toolkit"
import "firebase/auth"
import { map } from "lodash"
import { firebaseLogout } from "./firebase"
import { cometChatGetAuthToken, cometChatLogout } from "./cometChat"

interface UserState {
  firebase: object | {}
  cometChat: object | {}
  data: object | {}
}

const initialState: UserState = {
  firebase: {},
  cometChat: {},
  data: {},
}

export const adminUids = {
  m: "odezob2bzptb4brvtx8ryj7bra43", // manishie@manishie.com
  l: "fu7du5s1k5gj8bnoqvwhgy3mxhq2", // mkm43@caa.columbia.edu
  k: "til1c6fletsnjm3aildrdvj5pwz2", // manishiehome@gmail.com
}
/*
 * lizapascal@gmail.com: 5zc8r85l3tp5vxit5qal5lnsuux1
 * truebirth.info@gmail.com: alzmpkqvxyaqwubljpprcutknbl2
 * */

export const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    setFirebaseLoggedIn: (state, { payload }) => {
      state.firebase = payload.user
    },
    setFirebaseLoggedOut: (state) => {
      // setFirebaseLoggedOut: (state, action) => {
      state.firebase = null
    },
    // setFirebaseUser: (state, { payload }) => {
    //   state.firebase = { ...state.firebase, ...payload.user }
    // },
    setFirebaseUserData: (state, { payload }) => {
      state.data = payload.data || {}
    },
    setFirebaseUserDocId: (state, { payload }) => {
      // @ts-ignore
      state.firebase.userDocId = payload.userDocId
    },
    setCCAuthToken: (state, { payload }) => {
      // @ts-ignore
      state.cometChat.authToken = payload.cometChatAuthToken
    },
    setCCLoggedIn: (state) => {
      // @ts-ignore
      state.cometChat.isLoggedIn = true
    },
    setCCLoggedOut: (state) => {
      // @ts-ignore
      state.cometChat.isLoggedIn = false
    },
  },
})

export const {
  setFirebaseLoggedIn,
  setFirebaseLoggedOut,
  setFirebaseUserData,
  setFirebaseUserDocId,
  setCCAuthToken,
  setCCLoggedIn,
  setCCLoggedOut,
} = userSlice.actions

// selectors
// export const selectFirebaseData = (state) => {
//   // console.log(param)
// }
export const selectCCLoggedIn = (state) => state.user.cometChat?.isLoggedIn
export const selectUserDocId = (state) => state.user?.firebase?.userDocId

export const isAdminUser = (user) =>
  map(adminUids, (uid) => uid).includes(user?.uid.toLowerCase())

// const selectUser = (state: ReturnType<typeof store.getState>) =>
//   state.firebase.user

export const userLogout = (auth, logoutAuth0) => async (dispatch) => {
  // export const userLogout = (auth, history, logoutAuth0) => async (dispatch) => {
  firebaseLogout(auth, dispatch)
  cometChatLogout(dispatch)
  logoutAuth0({ logoutParams: { returnTo: window.location.origin } })

  // history.push("/")
}

/*
userLogin is called by the firebase listener after a user has successfully logged into firebase.  We will continue
the login process here
 */
export const userLogin = (user, dispatch) => async (dispatch) => {
  dispatch(setFirebaseLoggedIn({ user: user.toJSON() }))

  // comet AdminChat
  cometChatGetAuthToken(await user.getIdToken(), dispatch)
}

export default userSlice.reducer
// export const selectUser = (state) => {
//   return state.firebase.user
// }
// export const loginPassword =
//   ({ email, password }) =>
//   async (dispatch, state) => {
//     await firebase.auth().signInWithEmailAndPassword(email, password)
//
//     dispatch(setFirebaseLoggedIn({ user: firebase.auth().currentUser.toJSON() }))
//     dispatch(getUser())
//
//     return state().firebase.user
//   }
//

// export const getUser = () => async (dispatch, state) => {
//   let user = null
//   const uid = firebase.auth().currentUser.uid
//
//   // query user data
//   const resp = await firebase.firestore().collection("users").doc(uid).get()
//   if (resp.exists) {
//     user = {
//       id: resp.id,
//       ...resp.data(),
//     }
//   }
//
//   // update store
//   dispatch(setFirebaseUser({ user }))
//   return state().firebase.user
// }

/**
 *
 */
// export const createUser =
//   ({ email, password }) =>
//   async (dispatch, state) => {
//     // create auth user
//     const resp = await firebase
//       .auth()
//       .createUserWithEmailAndPassword(email, password)
//
//     // add user to user collection
//     const uid = firebase.auth().currentUser.uid
//     await firebase.firestore().collection("users").doc(uid).set({
//       id: uid,
//       email: resp.user.email,
//     })
//
//     // update store
//     dispatch(CREATE_USER({ user: firebase.auth().currentUser.toJSON() }))
//     const user = {
//       ...firebase.auth().currentUser.toJSON(),
//       id: uid,
//       email: resp.user.email,
//     }
//     dispatch(setFirebaseUser({ user }))
//   }

// export const { SET_INITIALIZED, setFirebaseLoggedIn, setFirebaseLoggedOut, setFirebaseUser, CREATE_USER } =
//   slice.actions
//
// export const { login, logout } = doulaverse.actions
//
